<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="name" v-validate="{ max: 255 }"  name="name" id="name" class="form-control form-control-sm" placeholder="Enter name">
              <div class="invalid-feedback">Name is required</div>
            </div>
            <div class="col-md-6">
              <label for="phone_no" class="col-form-label col-form-label-sm">Phone No. <span class="custom-required">*</span></label>
              <input type="text" v-model="phone_no" v-validate="{ regex: /^(8801[13456789]{1}[0-9]{8}|01[13456789]{1}[0-9]{8})$/}" name="phone_no" id="phone_no" class="form-control form-control-sm" placeholder="Enter phone no.">
              <div class="invalid-feedback">Phone number is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="father_name" class="col-form-label col-form-label-sm">Father`s Name <span class="custom-required">*</span></label>
              <input type="text" v-model="father_name" v-validate="{ max: 255 }"  name="father_name" id="father_name" class="form-control form-control-sm" placeholder="Enter father`s name">
              <div class="invalid-feedback">Father`s name is required</div>
            </div>
            <div class="col-md-6">
              <label for="father_phone_no" class="col-form-label col-form-label-sm">Father`s Phone No. <span class="custom-required">*</span></label>
              <input type="text" v-model="father_phone_no" v-validate="{ regex: /^(8801[13456789]{1}[0-9]{8}|01[13456789]{1}[0-9]{8})$/}" name="father_phone_no" id="father_phone_no" class="form-control form-control-sm" placeholder="Enter father`s phone no.">
              <div class="invalid-feedback">Father`s phone number is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="mother_name" class="col-form-label col-form-label-sm">Mother`s Name <span class="custom-required">*</span></label>
              <input type="text" v-model="mother_name" v-validate="{ max: 255 }"  name="mother_name" id="mother_name" class="form-control form-control-sm" placeholder="Enter mother`s name">
              <div class="invalid-feedback">Mother`s name is required</div>
            </div>
            <div class="col-md-6">
              <label for="mother_phone_no" class="col-form-label col-form-label-sm">Mother`s Phone No.</label>
              <input type="text" v-model="mother_phone_no" v-validate="{ regex: /^(8801[13456789]{1}[0-9]{8}|01[13456789]{1}[0-9]{8})$/}" name="mother_phone_no" id="mother_phone_no" class="form-control form-control-sm" placeholder="Enter mother`s phone no.">
              <div class="invalid-feedback">Mother`s phone number format invalid!</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="address" class="col-form-label col-form-label-sm">Address <span class="custom-required">*</span></label>
              <textarea v-model="address" v-validate="{ min: 2, max: 255 }"  name="address" id="address" class="form-control form-control-sm" placeholder="Enter address"> </textarea>
              <div class="invalid-feedback">Address is required</div>
            </div>
            <div class="col-md-6">
              <label for="joining_date" class="col-form-label col-form-label-sm">Joining Date <span class="custom-required">*</span></label>
              <datepicker :format="customJoiningDateFormat" v-model="joining_date" :class="errors.has('joining_date') ? 'error-border-color' : ''"  id="joining_date"  name="joining_date" placeholder="Select joining date" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('joining_date')">Joining date is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="current_salary" class="col-form-label col-form-label-sm">Current Salary <span class="custom-required">*</span></label>
              <input type="number" v-model="current_salary" v-validate="{ min: 2, max: 7, regex: /^[1-9][0-9]*$/  }"  name="current_salary" id="current_salary" class="form-control form-control-sm" placeholder="Enter current salary">
              <div class="invalid-feedback">Current salary is required</div>
            </div>
            <div class="col-md-6">
              <label for="last_increment_amount" class="col-form-label col-form-label-sm">Last Increment Amount<span class="custom-required">*</span></label>
              <input type="number" v-model="last_increment_amount" v-validate="{ min: 2, max: 7, regex: /^[1-9][0-9]*$/  }"  name="last_increment_amount" id="last_increment_amount" class="form-control form-control-sm" placeholder="Enter last increment amount">
              <div class="invalid-feedback">Last increment amount is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="last_date_of_increment" class="col-form-label col-form-label-sm">Last Date of Increment<span class="custom-required">*</span></label>
              <datepicker :format="customLastDateOfIncrementFormat" v-model="last_date_of_increment" :class="errors.has('last_date_of_increment') ? 'error-border-color' : ''"  id="last_date_of_increment"  name="last_date_of_increment" placeholder="Select last date of increment" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('last_date_of_increment')">Last date of increment is required</div>
            </div>
            <div class="col-md-6">
              <label for="hub_id" class="col-form-label col-form-label-sm">Hub <span class="custom-required">*</span></label>
              <select v-model="hub_id" id="hub_id" name="hub_id" class="form-control form-control-sm">
                <option selected  :value="''">Select a hub</option>
                <option v-for="(hub, index) in hubs" :key="index" :value="hub.id">{{ hub.name }}</option>
              </select>
              <div class="invalid-feedback">Hub is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="staff_employee_id" class="col-form-label col-form-label-sm">Employee ID<span class="custom-required">*</span></label>
              <input type="text" v-model="staff_employee_id" v-validate="{ max: 15 }"  name="staff_employee_id" id="staff_employee_id" class="form-control form-control-sm" placeholder="Enter employee ID">
              <div class="invalid-feedback">Employee ID is required</div>
            </div>
            <div class="col-md-6">
              <label for="staff_type_id" class="col-form-label col-form-label-sm">Staff Type <span class="custom-required">*</span></label>
              <select v-model="staff_type_id" id="staff_type_id" name="staff_type_id" class="form-control form-control-sm">
                <option selected  :value="''">Select a staff type</option>
                <option v-for="(staff_type, index) in staff_types" :key="index" :value="staff_type.id">{{ staff_type.name }}</option>
              </select>
              <div class="invalid-feedback">Staff type is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="working_area" class="col-form-label col-form-label-sm">Working Area <span class="custom-required">*</span></label>
              <input type="text" v-model="working_area" v-validate="{ max: 255 }"  name="working_area" id="working_area" class="form-control form-control-sm" placeholder="Enter working area">
              <div class="invalid-feedback">Working area is required</div>
            </div>
            <div class="col-md-6">
              <label for="profile_picture" class="col-form-label col-form-label-sm">Profile Picture <span style="font-weight: 100;">*Previous file <a :href="profile_picture_url" target="_blank">click here</a></span></label>
              <input type="file" ref="profilePicture" id="profile_picture" name="profile_picture" @change="getProfilePicture()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">Profile picture is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="nid" class="col-form-label col-form-label-sm">NID <span style="font-weight: 100;">*Previous file <a :href="nid_url" target="_blank">click here</a></span></label>
              <input type="file" ref="nid" id="nid" name="nid" @change="getNid()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">NID is required</div>
            </div>
            <div class="col-md-6">
              <label for="office_id_image" class="col-form-label col-form-label-sm">Office ID Image <span style="font-weight: 100;">*Previous file <a :href="office_id_image_url" target="_blank">click here</a></span></label>
              <input type="file" ref="officeIdImage" id="office_id_image" name="office_id_image" @change="getOfficeIdImage()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">Office id image is required</div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h5>Staff Contract info and Leave Types</h5>
          <hr>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6 center">
              <label for="contract_start_date" class="col-form-label col-form-label-sm"> Contract Start Date <span class="custom-required">*</span></label>
              <datepicker :format="customContractStartDateFormat" v-validate="{ rules: { required:  true } }" :class="errors.has('contract_start_date') ? 'error-border-color' : ''"   id="contract_start_date" v-model="contract_start_date"  name="contract_start_date" placeholder="Select contract start date" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('contract_start_date')">Contract start date required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6 center">
              <label for="contract_end_date" class="col-form-label col-form-label-sm"> Contract End Date <span class="custom-required">*</span></label>
              <datepicker :format="customContractEndDateFormat" v-validate="{ rules: { required:  true } }" :class="errors.has('contract_end_date') ? 'error-border-color' : ''"   id="contract_end_date" v-model="contract_end_date"  name="contract_end_date" placeholder="Select contract end date" ></datepicker>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('contract_end_date')">Contract end date required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="staff_leave_types" class="col-form-label col-form-label-sm">Staff Leave Type <span class="custom-required">*</span></label>
              <v-select v-model="staff_leave_types" multiple  :class="errors.first('staff_leave_types') ? 'borderColor' : ''" name="staff_leave_types" id="staff_leave_types" v-validate="'required'" label="name" :options="staff_leave_type_datas"></v-select>
              <div v-if="errors.first('staff_leave_types')" class="error">Staff leave type is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <a-button class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="update"><i class="fa fa-edit mr-1" aria-hidden="true"></i>Update</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { mapGetters } from 'vuex'

export default {
  name: 'create',
  components: { Datepicker },
  data() {
    return {
      hubs: [],
      staff_types: [],
      staff_leave_type_datas: [],
      submitLoading: false,
      name: '',
      phone_no: '',
      father_name: '',
      father_phone_no: '',
      mother_name: '',
      mother_phone_no: '',
      joining_date: '',
      current_salary: '',
      working_area: '',
      last_date_of_increment: '',
      last_increment_amount: '',
      hub_id: '',
      staff_employee_id: '',
      staff_type_id: '',
      address: '',
      staff_leave_types: [],
      contract_start_date: '',
      contract_end_date: '',
      staffId: '',
      profile_picture: '',
      nid: '',
      office_id_image: '',
      profile_picture_url: '',
      nid_url: '',
      office_id_image_url: '',
      validation_errors: {},
      show: false,
    }
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
  mounted() {
    this.getCodes()
    this.edit()
  },
  methods: {
    customContractStartDateFormat(date) {
      const contractStartDate = moment(date).format('YYYY-MM-DD')
      this.contract_start_date = contractStartDate
      return contractStartDate
    },
    customContractEndDateFormat(date) {
      const contractEndDate = moment(date).format('YYYY-MM-DD')
      this.contract_end_date = contractEndDate
      return contractEndDate
    },
    customJoiningDateFormat(date) {
      const joiningDate = moment(date).format('YYYY-MM-DD')
      this.joining_date = joiningDate
      return joiningDate
    },
    customLastDateOfIncrementFormat(date) {
      const lastDateOfIncrement = moment(date).format('YYYY-MM-DD')
      this.last_date_of_increment = lastDateOfIncrement
      return lastDateOfIncrement
    },
    getCodes() {
      apiClient.get('/api/staff/codes')
        .then(response => {
          this.hubs = response.data.hubs
          this.staff_types = response.data.staff_types
          this.staff_leave_type_datas = response.data.staff_leave_types
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit() {
      this.loading = true
      const supportStaffId = this.$route.params.staff_id
      apiClient.get('api/staffs/edit/' + supportStaffId).then(response => {
        this.loading = false
        if (!response.data.error) {
          const data = response.data.staff
          this.staffId = data.id
          this.name = data.name
          this.phone_no = data.phone_no
          this.father_name = data.father_name
          this.father_phone_no = data.father_phone_no
          this.mother_name = data.mother_name
          this.mother_phone_no = data.mother_phone_no
          this.joining_date = data.joining_date
          this.current_salary = data.current_salary
          this.working_area = data.working_area
          this.last_date_of_increment = data.last_date_of_increment
          this.last_increment_amount = data.last_increment_amount
          this.hub_id = data.hub_id
          this.staff_employee_id = data.staff_employee_id
          this.staff_type_id = data.staff_type_id
          this.address = data.address
          this.profile_picture_url = data.profile_picture_url
          this.nid_url = data.nid_url
          this.office_id_image_url = data.office_id_image_url
          const staffContractDate = response.data.staff_contract_date
          this.contract_start_date = staffContractDate.contract_start_date
          this.contract_end_date = staffContractDate.contract_end_date
          this.staff_leave_types = staffContractDate.staff_leave_types
        } else {
          this.$notification.error({
            message: response.data.message,
          })
        }
      })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.submitLoading = true
          const formData = new FormData()
          formData.append('name', this.name)
          formData.append('phone_no', this.phone_no)
          formData.append('father_name', this.father_name)
          formData.append('father_phone_no', this.father_phone_no)
          formData.append('mother_name', this.mother_name)
          formData.append('mother_phone_no', this.mother_phone_no)
          formData.append('joining_date', this.joining_date)
          formData.append('current_salary', this.current_salary)
          formData.append('working_area', this.working_area)
          formData.append('last_date_of_increment', this.last_date_of_increment)
          formData.append('last_increment_amount', this.last_increment_amount)
          formData.append('hub_id', this.hub_id)
          formData.append('staff_employee_id', this.staff_employee_id)
          formData.append('staff_type_id', this.staff_type_id)
          formData.append('address', this.address)
          formData.append('profile_picture', this.profile_picture)
          formData.append('nid', this.nid)
          formData.append('office_id_image', this.office_id_image)
          formData.append('staff_leave_types', JSON.stringify(this.staff_leave_types))
          formData.append('contract_end_date', this.contract_end_date)
          formData.append('contract_start_date', this.contract_start_date)
          apiClient.post('api/staffs/update/' + this.staffId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'staffHubList' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    getProfilePicture() {
      this.profile_picture = this.$refs.profilePicture.files[0]
      if (this.profile_picture.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.profile_picture = ''
      }
    },
    getNid() {
      this.nid = this.$refs.nid.files[0]
      if (this.nid.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.nid = ''
      }
    },
    getOfficeIdImage() {
      this.office_id_image = this.$refs.officeIdImage.files[0]
      if (this.office_id_image.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.office_id_image = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      //
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.error{
     display: block;
     width: 100%;
     margin-top: 0.25rem;
     font-size: 80%;
     color: #dc3545;
   }

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
